@font-face {
    font-family: "SFUIText";
    src: url("./shared/fonts/SFUIText-Regular.woff2") format("woff2"),
    url("./shared/fonts/SFUIText-Regular.woff") format("woff"),
    url("./shared/fonts/SFUIText-Regular.eot") format("embedded-opentype"),
    url("./shared/fonts/SFUIText-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "SFUITextBold";
    src: url("./shared/fonts/SFUIText-Bold.woff2") format("woff2"),
    url("./shared/fonts/SFUIText-Bold.woff") format("woff"),
    url("./shared/fonts/SFUIText-Bold.eot") format("embedded-opentype"),
    url("./shared/fonts/SFUIText-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "SFUITextBoldItalic";
    src: url("./shared/fonts/SFUIText-BoldItalic.woff2") format("woff2"),
    url("./shared/fonts/SFUIText-BoldItalic.woff") format("woff"),
    url("./shared/fonts/SFUIText-BoldItalic.eot") format("embedded-opentype"),
    url("./shared/fonts/SFUIText-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "SFUITextHeavy";
    src: url("./shared/fonts/SFUIText-Heavy.woff2") format("woff2"),
    url("./shared/fonts/SFUIText-Heavy.woff") format("woff"),
    url("./shared/fonts/SFUIText-Heavy.eot") format("embedded-opentype"),
    url("./shared/fonts/SFUIText-Heavy.ttf") format("truetype");
}

@font-face {
    font-family: "SFUITextLight";
    src: url("./shared/fonts/SFUIText-Light.woff2") format("woff2"),
    url("./shared/fonts/SFUIText-Light.woff") format("woff"),
    url("./shared/fonts/SFUIText-Light.eot") format("embedded-opentype"),
    url("./shared/fonts/SFUIText-Light.ttf") format("truetype");
}

@font-face {
    font-family: "SFUITextLightItalic";
    src: url("./shared/fonts/SFUIText-LightItalic.woff2") format("woff2"),
    url("./shared/fonts/SFUIText-LightItalic.woff") format("woff"),
    url("./shared/fonts/SFUIText-LightItalic.eot") format("embedded-opentype"),
    url("./shared/fonts/SFUIText-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "SFUITextMedium";
    src: url("./shared/fonts/SFUIText-Medium.woff2") format("woff2"),
    url("./shared/fonts/SFUIText-Medium.woff") format("woff"),
    url("./shared/fonts/SFUIText-Medium.eot") format("embedded-opentype"),
    url("./shared/fonts/SFUIText-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "SFUITextMediumItalic";
    src: url("./shared/fonts/SFUIText-MediumItalic.woff2") format("woff2"),
    url("./shared/fonts/SFUIText-MediumItalic.woff") format("woff"),
    url("./shared/fonts/SFUIText-MediumItalic.eot") format("embedded-opentype"),
    url("./shared/fonts/SFUIText-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "SFUITextSemibold";
    src: url("./shared/fonts/SFUIText-Semibold.woff2") format("woff2"),
    url("./shared/fonts/SFUIText-Semibold.woff") format("woff"),
    url("./shared/fonts/SFUIText-Semibold.eot") format("embedded-opentype"),
    url("./shared/fonts/SFUIText-Semibold.ttf") format("truetype");
}

@font-face {
    font-family: "FiraSansMedium";
    src: url("./shared/fonts/FiraSans-Medium.woff2") format("woff2"),
    url("./shared/fonts/FiraSans-Medium.woff") format("woff"),
    url("./shared/fonts/FiraSans-Medium.eot") format("embedded-opentype"),
    url("./shared/fonts/FiraSans-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "RobotoRegular";
    src: url("./shared/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./shared/fonts/Roboto-Regular.woff") format("woff"),
    url("./shared/fonts/Roboto-Regular.eot") format("embedded-opentype"),
    url("./shared/fonts/Roboto-Regular.ttf") format("truetype");
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background-color: #f9f5f0;
}

@media (max-width: 767px) {
    .container {
        width: 100%;
    }
}
